import React from 'react'
import Layout from '../layout'
import { Cart as CartPage } from '../components/pages'

interface Props {
  location: any
}

const Cart: React.FC<Props> = ({ location }) => (
  <Layout pageName="Cart" location={location}>
    <CartPage />
  </Layout>
)

export default Cart
